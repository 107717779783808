import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'

const routes = [
  {
    path: '/:error?',
    name: 'Login',
    props: true,
    component: () => import('../views/Login.vue')
  },
  {
    path: '/manageHomepageService',
    name: 'ManageHomepageService',
    meta: {
      requiresAuth: true,
    },
    component: () => import('../views/ManageHomepageService/ManageHomepageService.vue')
  },
  {
    path: '/addHomepageService',
    name: 'AddHomepageService',
    meta: {
      requiresAuth: true,
    },
    component: () => import('../views/ManageHomepageService/AddHomepageService.vue')
  },
  {
    path: '/editHomepageService/:homepageServiceID',
    name: 'EditHomepageService',
    props: true,
    meta: {
      requiresAuth: true,
    },
    component: () => import('../views/ManageHomepageService/EditHomepageService.vue')
  },
  {
    path: '/manageBanner',
    name: 'ManageBanner',
    meta: {
      requiresAuth: true,
    },
    component: () => import('../views/ManageBanner/ManageBanner.vue')
  },
  {
    path: '/addBanner',
    name: 'AddBanner',
    meta: {
      requiresAuth: true,
    },
    component: () => import('../views/ManageBanner/AddBanner.vue')
  },
  {
    path: '/manageGallery',
    name: 'ManageGallery',
    meta: {
      requiresAuth: true,
    },
    component: () => import('../views/AboutGallery/ManageGallery.vue')
  },
  {
    path: '/addGalleryImage',
    name: 'AddGalleryImage',
    meta: {
      requiresAuth: true,
    },
    component: () => import('../views/AboutGallery/AddGalleryImage.vue')
  },
  {
    path: '/manageServiceCategory',
    name: 'ManageServiceCategory',
    meta: {
      requiresAuth: true,
    },
    component: () => import('../views/Service/ManageServiceCategory.vue')
  },
  {
    path: '/addServiceCategory',
    name: 'AddServiceCategory',
    meta: {
      requiresAuth: true,
    },
    component: () => import('../views/Service/AddServiceCategory.vue')
  },
  {
    path: '/manageService',
    name: 'ManageService',
    meta: {
      requiresAuth: true,
    },
    component: () => import('../views/Service/ManageService.vue')
  },
  {
    path: '/addService',
    name: 'AddService',
    meta: {
      requiresAuth: true,
    },
    component: () => import('../views/Service/AddService.vue')
  },
  {
    path: '/editService/:serviceID',
    name: 'EditService',
    props: true,
    meta: {
      requiresAuth: true,
    },
    component: () => import('../views/Service/EditService.vue')
  },
  {
    path: '/manageProductCategory',
    name: 'ManageProductCategory',
    meta: {
      requiresAuth: true,
    },
    component: () => import('../views/Product/ManageProductCategory.vue')
  },
  {
    path: '/addProductCategory',
    name: 'AddProductCategory',
    meta: {
      requiresAuth: true,
    },
    component: () => import('../views/Product/AddProductCategory.vue')
  },
  {
    path: '/manageProduct',
    name: 'ManageProduct',
    meta: {
      requiresAuth: true,
    },
    component: () => import('../views/Product/ManageProduct.vue')
  },
  {
    path: '/addProduct',
    name: 'AddProduct',
    meta: {
      requiresAuth: true,
    },
    component: () => import('../views/Product/AddProduct.vue')
  },
  {
    path: '/editProduct/:productID',
    name: 'EditProduct',
    props: true,
    meta: {
      requiresAuth: true,
    },
    component: () => import('../views/Product/EditProduct.vue')
  },
  {
    path: '/manageDoctor',
    name: 'ManageDoctor',
    meta: {
      requiresAuth: true,
    },
    component: () => import('../views/Doctor/ManageDoctor.vue')
  },
  {
    path: '/addDoctor',
    name: 'AddDoctor',
    meta: {
      requiresAuth: true,
    },
    component: () => import('../views/Doctor/AddDoctor.vue')
  },
  {
    path: '/editDoctor/:doctorID',
    name: 'EditDoctor',
    props: true,
    meta: {
      requiresAuth: true,
    },
    component: () => import('../views/Doctor/EditDoctor.vue')
  },
  {
    path: '/manageUserAccount',
    name: 'ManageUserAccount',
    meta: {
      requiresAuth: true,
    },
    component: () => import('../views/UserAccount/ManageUserAccount.vue')
  },
  {
    path: '/addUserAccount',
    name: 'AddUserAccount',
    meta: {
      requiresAuth: true,
    },
    component: () => import('../views/UserAccount/AddUserAccount.vue')
  },
  {
    path: '/editUserAccount/:userID',
    name: 'EditUserAccount',
    props: true,
    meta: {
      requiresAuth: true,
    },
    component: () => import('../views/UserAccount/EditUserAccount.vue')
  },
  {
    path: '/updatePassword',
    name: 'UpdatePassword',
    meta: {
      requiresAuth: true,
    },
    component: () => import('../views/UserAccount/UpdatePassword.vue')
  },
  {
    path: '/manageCompanyInfo',
    name: 'ManageCompanyInfo',
    meta: {
      requiresAuth: true,
    },
    component: () => import('../views/ManageCompanyInfo.vue')
  },
  {
    path: '/manageBranch',
    name: 'ManageBranch',
    meta: {
      requiresAuth: true,
    },
    component: () => import('../views/Branch/ManageBranch.vue')
  },
  {
    path: '/addBranch',
    name: 'AddBranch',
    meta: {
      requiresAuth: true,
    },
    component: () => import('../views/Branch/AddBranch.vue')
  },
  {
    path: '/editBranch/:branchID',
    name: 'EditBranch',
    props: true,
    meta: {
      requiresAuth: true,
    },
    component: () => import('../views/Branch/EditBranch.vue')
  },
  {
    path: '/seo-management',
    name: 'SEOManagement',
    props: true,
    meta: {
      requiresAuth: true,
    },
    component: () => import('../views/SeoSettings/index.vue')
  },
  {
    path: '/seo-management/:page',
    name: 'EditSEO',
    props: true,
    meta: {
      requiresAuth: true,
    },
    component: () => import('../views/SeoSettings/seo-settings-form.vue')
  },
  {
    path: '/subpage-banner',
    name: 'subpage-banner',
    meta: {
      requiresAuth: true,
    },
    component: () => import('../views/subpage-banner.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const userData = store.getters.getUserData;

  if (requiresAuth) {
    if (userData == null) {
      next('/')
    } else {
      next()
    }
  } else {
    next()
  }

})

export default router
