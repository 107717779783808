<template>
  <router-view />
</template>

<style>
#app a {
  color: #374151;
  font-weight: 600;
  font-size: 14px;
}

#app .sidenavbar button {
  color: #374151;
  font-weight: 600;
  font-size: 14px;
}
</style>
