import { createStore } from 'vuex'

export default createStore({
  state: {
    userData: JSON.parse(localStorage.getItem("vuex") || `{"userData": null}`),
  },
  getters: {
    getUserData(state) {
      return state.userData;
    },
  },
  mutations: {
    saveUserData(state, userData) {
      state.userData = userData
    },
  },
  actions: {
    createUserSession({ commit }, userData) {
      localStorage.setItem("vuex", JSON.stringify(userData));
      commit("saveUserData", userData)
    },
    removeUserSession({ commit }) {
      localStorage.setItem("vuex", `{"userData": null}`);
      commit("saveUserData", { userData: null })
    },
    async logout({ dispatch }, vm) {
      if (vm != null) {
        vm.$router.push("/");
      }

      await dispatch("removeUserSession");
    },
  }
})
